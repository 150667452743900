import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const ShowroomPage = React.lazy(() => import('./pages/showroom.page'));

const App: React.FC = () => {
  return (
    <Router  basename="/ford-mx-test">
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<ShowroomPage />} />
          {/* <Route path="/canva" element={<CanvasPage />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
